import React from 'react';
import { Card, Stack, Typography } from '@mui/material';
import { FiCalendar, FiClipboard } from 'react-icons/fi';

import { EarningEngagementsResponseDto } from '@keyops-hcp/dtos';
import { theme } from '@keyops-hcp/ui-components';

import i18next from '../../../languages/i18n.config';

type EarningCardProps = {
  engagement: EarningEngagementsResponseDto;
};

export const EarningCard = ({
  engagement: earningEngagement,
}: EarningCardProps) => {
  return (
    <Card
      sx={{
        borderRadius: '8px',
        padding: '16px',
        margin: '8px 0',
        border: `1px solid ${theme.palette.keyops.gray[200]}`,
        boxShadow: 'none',
      }}
    >
      <Typography
        paddingBottom={earningEngagement.completedAt ? `14px` : `0px`}
      >
        {earningEngagement.title}
      </Typography>
      <Stack
        direction={'row'}
        display="flex"
        justifyContent={'space-between'}
        alignContent={'center'}
      >
        <Stack
          id="survey-info"
          direction="column"
          justifyContent={
            earningEngagement.completedAt ? 'space-between' : 'center'
          }
        >
          <Stack
            direction={'row'}
            display={'flex'}
            alignItems={'center'}
            spacing={1}
          >
            <FiClipboard
              color={theme.palette.keyops.blue.midnightBlue}
              fontSize={'14px'}
            />
            <Typography fontSize={'12px'} lineHeight={'16px'}>
              {earningEngagement.type}
            </Typography>
          </Stack>
          {earningEngagement.completedAt && (
            <Stack
              direction={'row'}
              display={'flex'}
              alignItems={'center'}
              spacing={1}
            >
              <FiCalendar
                color={theme.palette.keyops.blue.midnightBlue}
                fontSize={'14px'}
              />
              <Typography fontSize={'12px'} lineHeight={'16px'}>
                {i18next.t('earnings.completedAtText', {
                  completedDate: new Date(
                    earningEngagement.completedAt,
                  ).toLocaleDateString('en-CA', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  }),
                })}
              </Typography>
            </Stack>
          )}
        </Stack>
        <Stack
          id="earnings-info"
          direction="column"
          justifyContent={'space-between'}
        >
          <Typography
            align="right"
            color={theme.palette.keyops.blue.keyopsBlue}
            fontWeight={500}
          >
            {earningEngagement.amount ? `$${earningEngagement.amount}` : `$0`}
          </Typography>
          <Typography align="right" fontSize={'12px'} lineHeight={'16px'}>
            {i18next.t('earnings.earned')}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};
