// External Dependencies
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Divider, Stack, Typography } from '@mui/material/';

// Internal Dependencies
import {
  isModeratorUser,
  isNurseUser,
  isPharmacistUser,
  isPhysicianUser,
  UserDto,
} from '@keyops-hcp/dtos';
import {
  analytics,
  formatAuthorName,
  KeyOpsAvatar,
  KeyopsHeader2,
  theme,
} from '@keyops-hcp/ui-components';
import Loading from '../../../../../components/Loading';
import { PhysiciansProfileFields } from './PhysiciansProfileFields';
import { PharmacistsProfileFields } from './PharmacistsProfileFields';
import { NursesProfileFields } from './NursesProfileFields';
import { ModeratorProfileFields } from './ModeratorProfileFields';

// TODO: move to ui-components?
export const Profile = ({
  userData,
  isLoading,
}: {
  userData: UserDto;
  isLoading: boolean;
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!isLoading && userData) {
      analytics.track('Profile');
    }
  }, [isLoading]);

  const isUserDataDefined = userData && Object.keys(userData).length > 0;

  // 2.1 Return load screen if data is not yet loaded
  if (isLoading) {
    return <Loading />;
  }

  // 2.2 Return Profile page component
  return (
    <Box px={2} py={2} position="sticky" top={0} zIndex={1} bgcolor={'#fff'}>
      <KeyopsHeader2>{t('profile.profileDetails.title')}</KeyopsHeader2>
      {isUserDataDefined && (
        <Stack direction={'row'} alignItems={'center'} spacing={4} py={3}>
          <KeyOpsAvatar
            text={formatAuthorName(
              [userData.firstName, userData.lastName],
              true,
            )}
            role={userData.hcpType}
            width={'98px'}
            height={'98px'}
            fontSize={'40px'}
          />

          <Box>
            <Typography fontSize={'16px'}>
              {formatAuthorName([userData.firstName, userData.lastName], false)}
            </Typography>
            <Typography>{userData.email}</Typography>
          </Box>
        </Stack>
      )}

      <Divider
        sx={{
          borderColor: theme.palette.keyops.blue.lightSteelBlue20,
        }}
      />

      {/* TODO: consider moving this code to useMemo with userData.hcpType as a dependency, so it does not have to check for user type on every re-render, instead it will only check when userData.hcpType gets changed. */}
      <Grid container spacing={1.5} py={1.5} px={0}>
        {isPhysicianUser(userData) && (
          <PhysiciansProfileFields userData={userData} />
        )}
        {isPharmacistUser(userData) && (
          <PharmacistsProfileFields userData={userData} />
        )}
        {isNurseUser(userData) && <NursesProfileFields userData={userData} />}
        {isModeratorUser(userData) && (
          <ModeratorProfileFields userData={userData} />
        )}
      </Grid>
    </Box>
  );
};
