import React, { useEffect, useState } from 'react';

export const IQVIASignIn = () => {
  const [isIqviaButtonLoaded, setIsIqviaButtonLoaded] = useState(false);

  useEffect(() => {
    if (!isIqviaButtonLoaded) {
      window.hcaSdk.displayActiveButtons();
      setIsIqviaButtonLoaded(true);
    }
  }, [isIqviaButtonLoaded]);

  return <div id="hca_signin"></div>;
};
