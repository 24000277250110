// External Dependencies
import React, { createRef, useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import {
  Link,
  Typography,
  Alert,
  AlertTitle,
  Collapse,
  Divider,
  Box,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

// Internal Dependencies
import { StyledInputRoot, StyledInputElement, Outline } from './styles.js';
import { RoundedButton } from '../../styles/index.js';
import Logo from '../../images/KeyOpsLogo.svg';
import PasswordInput from '../../components/PasswordInput';
import { useAuth } from '../../higher-order-components/useAuth';
import GoogleButton from '../../components/GoogleButton';
import ToastContext from '../../components/Toast/ToastContext';
import { DASHBOARD } from '../../utils/routes';
import { KeyopsHeader1 } from '@keyops-hcp/ui-components';
import { IQVIASignIn } from '../../components/IQVIA';
import { axiosCall } from '../../utils/api.js';

export const Login = () => {
  const { t } = useTranslation();

  const { login, loginWithGoogle } = useAuth();
  const [searchParams] = useSearchParams();
  const referralId = searchParams.get('referralId');
  const { triggerToast } = useContext(ToastContext);
  const [isIqviaLoginEnabled, setIsIqviaLoginEnabled] = useState(false);

  useEffect(() => {
    axiosCall(true, 'get', '/feature-switches', undefined, {
      name: 'iqvia_idp',
    }).then((result) => {
      if (result?.data?.data?.featureSwitch?.enabled) {
        setIsIqviaLoginEnabled(true);
      }
    });
  }, [isIqviaLoginEnabled]);

  const googleButtonRef = createRef<HTMLDivElement>();

  const [values, setValues] = useState({
    email: '',
    password: '',
    showPassword: false,
    errorTitleText: '',
    errorSubTitleText: '',
    hidden: false,
  });

  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = async (event, field) => {
    setValues({ ...values, [field]: event.target.value, hidden: false });
    if (event.keyCode === 13) {
      signIn();
    }
  };

  const signIn = async () => {
    const email = values.email.trim();
    const password = values.password;
    const results = await login(email, password);
    const statusCode = results?.status;
    if (statusCode > 199 && statusCode < 300) {
      const redirectTo = location.state?.from?.pathname || DASHBOARD;
      navigate(
        referralId ? `${redirectTo}?referralId=${referralId}` : redirectTo,
        { replace: true },
      );
    } else {
      setValues({
        ...values,
        errorTitleText: t('signIn.errorTitle'),
        errorSubTitleText: t('signIn.errorSubTitle'),
        hidden: true,
      });
    }
  };

  const handleLoginWithGoogle = async (responseJWT) => {
    const results = await loginWithGoogle(responseJWT);
    if (results?.status !== 200) {
      const type = 'error';
      const message =
        results?.response?.status === 401
          ? (results?.response?.data?.message ?? t('signIn.noAccountFound'))
          : t('general.errorText');
      triggerToast({
        type: type,
        message: message,
        duration: 5000,
      });
    } else {
      const redirectTo = location.state?.from?.pathname || DASHBOARD;
      navigate(
        referralId ? `${redirectTo}?referralId=${referralId}` : redirectTo,
        { replace: true },
      );
    }
  };

  return (
    <Outline>
      {/* we add this to ensure the iqvia button does not cause a form submit */}
      <form onSubmit={(event) => event.preventDefault()}>
        <Stack alignItems="center" spacing={2}>
          <Box>
            <img
              style={{ height: 40 }}
              src={Logo}
              alt={t('general.keyOpsLogoAltText')}
            />
          </Box>
          <Box>
            <KeyopsHeader1 textAlign={'center'}>
              {t('signIn.welcome')}
            </KeyopsHeader1>
          </Box>
          <Box maxWidth="400px" width="100%">
            <StyledInputRoot>
              <StyledInputElement
                placeholder={t('signIn.email')}
                type={'text'}
                value={values.email}
                disableUnderline={true}
                onChange={(e) => {
                  handleChange(e, 'email');
                }}
              />
            </StyledInputRoot>
            <PasswordInput
              id="login-password"
              onChange={(e) => {
                handleChange(e, 'password');
              }}
            />
          </Box>
          {values.hidden && (
            <Collapse
              in={values.hidden}
              sx={{
                maxWidth: '400px',
                textAlign: 'left',
              }}
            >
              <Alert severity="warning">
                <AlertTitle>{values.errorTitleText}</AlertTitle>
                <Typography>{values.errorSubTitleText}</Typography>
              </Alert>
            </Collapse>
          )}
          <Box>
            <Typography>
              <Link href="/forgot-password">{t('signIn.forgotPassword')}</Link>
            </Typography>
          </Box>
          <Box maxWidth="400px" width="100%">
            <RoundedButton
              type="submit"
              variant="contained"
              fullWidth
              onClick={signIn}
            >
              {t('general.signIn')}
            </RoundedButton>
          </Box>
          <Divider sx={{ height: '100%', width: '100%' }}>
            <Typography variant="caption" textTransform={'uppercase'}>
              {t('general.or')}
            </Typography>
          </Divider>
          {isIqviaLoginEnabled ? <IQVIASignIn /> : <div />}
          <GoogleButton
            ref={googleButtonRef}
            callbackFunc={handleLoginWithGoogle}
            showOneTapDialog
          />

          <Typography textAlign={'center'}>
            {t('signIn.byContinuing')}{' '}
            <a href="https://www.keyops.io/terms-of-use">
              {t('signIn.termsOfUse')}
            </a>{' '}
            {t('general.and')}{' '}
            <a href="https://www.keyops.io/privacy-policy">
              {t('signIn.privacyPolicy')}
            </a>
            .
          </Typography>
        </Stack>
      </form>
    </Outline>
  );
};
