// External Dependencies
import React, { Fragment, useMemo } from 'react';
import {
  Alert,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Link,
  Typography,
  useTheme,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useFieldArray, useWatch, get } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// Internal Dependencies
import KeyOpsTextField from '../components/TextField';
import KeyOpsSingleSelect from '../components/SingleSelect';
import KeyOpsMultiSelect from '../components/MultiSelect';
import { FORM_FIELDS } from './form-constants';
import {
  PhysicianPracticeSettingOptions,
  SpecialtyOptions,
  SubSpecialtyOptionsBySpecialty,
} from '../../../utils/enum-display';
import { theme } from '@keyops-hcp/ui-components';
import { formatSpecialties } from '../../../utils/functions/eligibility.utils';

const formFields = FORM_FIELDS.practiceDetails.physician;
const MAX_SPECIALTY = 3;

const PhysicianPracticeDetails = ({
  control,
  register,
  errors,
  setValue,
  attachedEngagement,
}) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const [countryValue, specialtyFieldsValue, bypassWorkEmailVerificationValue] =
    useWatch({
      control,
      name: [
        FORM_FIELDS.accountDetails.country.name,
        formFields.specialtyFields.name,
        formFields.bypassWorkEmailVerification.name,
      ],
    });

  const specialtyEligibilityMessage = useMemo(() => {
    if (specialtyFieldsValue) {
      const allowedSpecialties = attachedEngagement?.allowedSpecialties || [];

      const selectedSpecialties = specialtyFieldsValue.map(
        (field) => field.specialty,
      );

      if (
        allowedSpecialties.length === 0 ||
        selectedSpecialties.every((item) => !item)
      ) {
        return null;
      }

      if (
        !allowedSpecialties.some((spec) => selectedSpecialties.includes(spec))
      ) {
        const allowedHcpType = 'physician';
        const returnedText =
          allowedSpecialties.length > 0 && allowedHcpType
            ? t(`onboarding.forms.eligibilityMessageWithSpecialties`, {
                requiredHCPType: allowedHcpType,
                requiredSpecialties: formatSpecialties(allowedSpecialties),
              })
            : t(`onboarding.forms.eligibilityMessageNoSpecialties`, {
                requiredHCPType: allowedHcpType,
              });

        return returnedText;
      }

      return null;
    } else {
      // set default value for specialtyFields
      setValue('physician.specialtyFields', [
        { specialty: undefined, subSpecialty: [] },
      ]);
    }
  }, [specialtyFieldsValue]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'physician.specialtyFields',
  });

  // Function to handle checkbox change
  const handleBypassCheckboxChange = (event) => {
    setValue(formFields.bypassWorkEmailVerification.name, event.target.checked);
  };

  return (
    <>
      {specialtyFieldsValue && (
        <Box>
          {fields.map((item, index) => (
            <Fragment key={item.id}>
              {index ? (
                <Divider sx={{ mt: index > 1 ? 2 : 4 }} flexItem />
              ) : null}
              <KeyOpsSingleSelect
                fieldName={`physician.specialtyFields.${index}.specialty`}
                placeholder={formFields.specialty.label}
                rules={{
                  required: t(`onboarding.forms.fieldErrors.required`, {
                    field: formFields.specialty.label,
                  }),
                }}
                options={SpecialtyOptions}
                control={control}
                errors={errors}
                sx={{ mt: index ? 3 : 0 }}
              />
              {index === 0 && specialtyEligibilityMessage ? (
                <Alert
                  severity="info"
                  icon={
                    <InfoOutlinedIcon
                      sx={{
                        mr: '-4px',
                        p: '2px 4px',
                      }}
                      htmlColor={theme.palette.keyops.blue.midnightBlue}
                    />
                  }
                  style={{
                    marginTop: 8,
                    padding: '12px 8px 12px 8px',
                    width: 'auto',
                    textAlign: 'left',
                    alignItems: 'top',
                    lineHeight: '24px',
                    fontSize: '16px',
                    maxWidth: 650,
                  }}
                >
                  {specialtyEligibilityMessage}
                </Alert>
              ) : null}
              <KeyOpsMultiSelect
                fieldName={`physician.specialtyFields.${index}.subSpecialty`}
                placeholder={formFields.subSpecialty.label}
                options={
                  SubSpecialtyOptionsBySpecialty[
                    specialtyFieldsValue[index]?.specialty
                  ] || []
                }
                disabled={
                  !SubSpecialtyOptionsBySpecialty[
                    specialtyFieldsValue[index]?.specialty
                  ]
                }
                control={control}
                errors={errors}
                sx={{ mt: 3 }}
              />
              {fields.length > 1 ? (
                <Box display={'flex'} justifyContent={'flex-end'}>
                  <Link
                    component={'button'}
                    variant={'body1'}
                    fontWeight={500}
                    underline={'none'}
                    sx={{ mt: 1 }}
                    onClick={(e) => {
                      e.preventDefault();
                      remove(index);
                    }}
                  >
                    {t('onboarding.forms.practiceDetails.remove')}
                  </Link>
                </Box>
              ) : null}
            </Fragment>
          ))}
          {specialtyFieldsValue?.length < MAX_SPECIALTY && (
            <Box>
              {specialtyFieldsValue.length > 1 && (
                <Divider sx={{ mt: 3 }} flexItem />
              )}
              <Link
                component={'button'}
                variant={'body1'}
                fontWeight={400}
                underline={'none'}
                sx={{ mt: specialtyFieldsValue.length === 1 ? 1 : 2 }}
                onClick={(e) => {
                  e.preventDefault();
                  append({ specialty: '', subSpecialty: [] });
                }}
              >
                {t('onboarding.forms.practiceDetails.addAnotherSpecialty')}
              </Link>
            </Box>
          )}
        </Box>
      )}
      <KeyOpsSingleSelect
        fieldName={formFields?.practiceSetting.name}
        placeholder={formFields?.practiceSetting.label}
        rules={{
          required: t(`onboarding.forms.fieldErrors.required`, {
            field: formFields.practiceSetting.label,
          }),
        }}
        options={PhysicianPracticeSettingOptions}
        control={control}
        errors={errors}
      />
      <KeyOpsTextField
        fieldName={formFields.licenseNumber.name}
        placeholder={
          countryValue === 'CA'
            ? t('onboarding.forms.practiceDetails.canadaLicenseNumber')
            : t('onboarding.forms.practiceDetails.usaLicenseNumber')
        }
        rules={{
          required:
            countryValue === 'CA'
              ? t(`onboarding.forms.fieldErrors.required`, {
                  field: t(
                    'onboarding.forms.practiceDetails.canadaLicenseNumber',
                  ),
                })
              : t(`onboarding.forms.fieldErrors.required`, {
                  field: t('onboarding.forms.practiceDetails.usaLicenseNumber'),
                }),
        }}
        register={register}
        errors={errors}
      />
      <KeyOpsTextField
        fieldName={formFields.practiceLicenseYear.name}
        placeholder={formFields.practiceLicenseYear.label}
        rules={{
          required: t(`onboarding.forms.fieldErrors.required`, {
            field: formFields.practiceLicenseYear.label,
          }),
        }}
        register={register}
        errors={errors}
      />
      <KeyOpsTextField
        fieldName={formFields.workEmail.name}
        placeholder={formFields.workEmail.label}
        inputProps={{ 'data-testid': formFields.workEmail.name }}
        rules={{
          required: t(`onboarding.forms.fieldErrors.required`, {
            field: formFields.workEmail.label,
          }),
        }}
        register={register}
        helperText={t('onboarding.forms.practiceDetails.workHelperText')}
        errors={bypassWorkEmailVerificationValue ? {} : errors}
        disabled={bypassWorkEmailVerificationValue}
      />
      {/* Checkbox to bypass work email validation */}
      {(get(errors, formFields.workEmail.name)?.message ===
        t('onboarding.forms.fieldErrors.workEmailNotValid') ||
        bypassWorkEmailVerificationValue) && (
        <FormControlLabel
          style={{ marginTop: '16px', alignItems: 'flex-start' }}
          control={
            <Checkbox
              disableRipple={true}
              sx={{
                '& .MuiSvgIcon-root': { fontSize: 20 },
                pl: 0,
                pr: 0.5,
                color: palette.keyops.black,
                marginTop: -1.1,
              }}
              onChange={handleBypassCheckboxChange}
              name={formFields.bypassWorkEmailVerification.name}
              checked={bypassWorkEmailVerificationValue}
            />
          }
          label={
            <Typography variant="body2">
              {formFields.bypassWorkEmailVerification.label}
            </Typography>
          }
        />
      )}
      <KeyOpsTextField
        fieldName={formFields.otherDetails.name}
        placeholder={formFields.otherDetails.label}
        register={register}
        errors={errors}
      />
    </>
  );
};

export default PhysicianPracticeDetails;
