import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { axiosCall } from '../utils/api';
import { useQuery } from '@keyops-hcp/ui-components';
import ToastContext from '../components/Toast/ToastContext';

const useReferralShareOptions = (engagementId: number | boolean) => {
  const { t } = useTranslation();

  const [linkCopied, setLinkCopied] = useState(false);
  const [shareReferralBody, setShareReferralBody] = useState('');
  const [shareMessageBody, setShareMessageBody] = useState('');
  const [emailSubject, setEmailSubject] = useState('');

  const { triggerToast } = useContext(ToastContext);

  const { isLoading, isError, data } = useQuery({
    queryKey: ['getReferralEngagementData', engagementId],
    queryFn: async () => {
      const response = await axiosCall(false, 'get', 'referral-link', false, {
        engagementId: engagementId,
      });
      const responseData = response?.data?.data;
      setEmailSubject(
        responseData.engagementPayout
          ? t('share.emailSubject', {
              engagementPayout: responseData.engagementPayout,
              estimatedTime: responseData.estimatedTime,
            })
          : t('share.emailSubjectNoPayout'),
      );
      setShareReferralBody(
        responseData.referralPayout
          ? t('share.body', { referralPayout: responseData.referralPayout })
          : t('share.bodyNoPayout'),
      );
      setShareMessageBody(
        responseData.engagementPayout
          ? t('share.shareBody', {
              engagementPayout: responseData.engagementPayout,
              estimatedTime: responseData.estimatedTime,
            })
          : t('share.shareBodyNoPayout'),
      );
      return responseData;
    },
    enabled: !!engagementId,
  });

  useEffect(() => {
    if (linkCopied) setTimeout(() => setLinkCopied(false), 5000);
  }, [linkCopied]);

  const handleCopyClick = async () => {
    navigator.clipboard.writeText(data.link);
    setLinkCopied(true);
    triggerToast({
      type: 'success',
      message: 'Link copied!',
      duration: 3000,
    });
  };

  const mailToClick = async () => {
    const mailtoLink = `mailto:?subject=${encodeURIComponent(
      emailSubject,
    )}&body=${encodeURIComponent(`${shareMessageBody}\n\n${data.link}`)}`;

    window.location.href = mailtoLink;
  };

  const mobileShare = () => {
    navigator.share({
      title: t('share.title'),
      text: shareMessageBody,
      url: data.link,
    });
  };

  return {
    isLoading,
    isError,
    shareReferralBody,
    mobileShare,
    handleCopyClick,
    linkCopied,
    mailToClick,
  };
};

export default useReferralShareOptions;
