import React from 'react';
import {
  FormControl,
  ToggleButton,
  ToggleButtonGroup,
  styled,
  toggleButtonClasses,
  toggleButtonGroupClasses,
} from '@mui/material';
import { Control, Controller, get } from 'react-hook-form';

import { Options } from '../forms/form-constants';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    textTransform: 'capitalize',
    fontWeight: 400,
    borderColor: theme.palette.keyops.gray[300],
    color: theme.palette.keyops.gray[600],
    backgroundColor: theme.palette.keyops.white.main,
    [`&.${toggleButtonClasses.selected}`]: {
      color: theme.palette.keyops.white.main,
      backgroundColor: theme.palette.keyops.blue.midnightBlue,
      '&:hover': {
        backgroundColor: theme.palette.keyops.blue.midnightBlue90,
      },
    },
  },
}));

type ValidationRuleType = {
  [key: string]: (value: string | null | undefined) => true | string;
};

type KeyOpsToggleButtonGroupProps = {
  fieldName: string;
  defaultValue?: string;
  rules?: { [key: string]: string } | ValidationRuleType; // validation rules
  options?: Options;
  control: Control;
  setValue?: (name: string, value: unknown, config?: object) => void;
  errors: object;
};

const KeyOpsToggleButtonGroup = ({
  fieldName,
  defaultValue = '',
  rules,
  options = [],
  control,
  setValue,
  errors,
  ...props
}: KeyOpsToggleButtonGroupProps) => {
  // TODO: remove because this function is not being called
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: 'physician' | 'pharmacist',
  ) => {
    setValue(fieldName, newValue);
  };
  const error = get(errors, fieldName);

  const renderInput = ({ field }) => (
    <StyledToggleButtonGroup
      exclusive
      fullWidth
      onChange={handleChange}
      aria-label="i-am-a-button-group"
      {...field}
      data-testid={fieldName}
    >
      {options.map((option) => (
        <ToggleButton key={option.value} value={option.value}>
          {option.label}
        </ToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );

  return (
    <FormControl error={!!error} {...props}>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={renderInput}
      />
    </FormControl>
  );
};

export default KeyOpsToggleButtonGroup;
