// External Dependencies
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Stack,
  Typography,
  TextField,
  useTheme,
} from '@mui/material/';

interface CustomRewardProps {
  claimReward: (claimed: string, top: number, height: number) => void;
  setShowCustomReward: (claimed: boolean) => void;
}

const CustomReward = ({
  claimReward,
  setShowCustomReward,
}: CustomRewardProps): JSX.Element => {
  const { t } = useTranslation();

  const boxRef = useRef(null);
  const [customReward, setCustomReward] = React.useState('');
  const theme = useTheme();

  return (
    <Box ref={boxRef}>
      <Typography
        aria-label="share"
        sx={{
          fontSize: '18px',
          fontWeight: 700,
          color: theme.palette.keyops.gray[700],
        }}
      >
        {t('rewards.claimReward.title')}
      </Typography>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 400,
          color: theme.palette.keyops.gray[700],
          pt: '16px',
          pb: '16px',
        }}
      >
        {t('rewards.claimReward.customRewardDescription')}
      </Typography>
      <>
        <TextField
          id="rewards-outlined-multiline-flexible"
          InputProps={{
            inputProps: {
              'data-testid': 'rewards-custom-reward-textarea',
            },
          }}
          multiline
          fullWidth
          minRows={3}
          sx={{ marginTop: 2.5, border: '1px solid var(--gray-200, #E2E8F0)' }}
          value={customReward}
          onChange={(e) => {
            setCustomReward(e.target.value);
          }}
        />
        <Stack direction={'row'} gap={2} sx={{ float: 'right', marginTop: 3 }}>
          <Button
            variant="outlined"
            sx={{
              fontSize: 16,
              textTransform: 'capitalize',
            }}
            onClick={() => {
              setShowCustomReward(false);
            }}
          >
            {t('general.back')}
          </Button>
          <Button
            variant="contained"
            disabled={customReward === ''}
            sx={{
              fontSize: 16,
              textTransform: 'capitalize',
            }}
            onClick={() => {
              claimReward(
                customReward,
                boxRef.current.getBoundingClientRect().top - 24, // offset 24px to make up for padding in basicModal
                boxRef.current.offsetHeight, // set current modal height for transition
              );
            }}
          >
            {t('rewards.claimReward.claimButton')}
          </Button>
        </Stack>
      </>
    </Box>
  );
};

export default CustomReward;
