// External Dependencies
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { UUID } from 'crypto';
import { analytics, getUrl, KeyopsHeader1 } from '@keyops-hcp/ui-components';

// Internal Dependencies
import { AccountDetails } from './forms/AccountDetails';
import { PersonalDetails } from './forms/PersonalDetails';
import { PracticeDetails } from './forms/PracticeDetails';
import { EngageWithKeyops } from './forms/EngageWithKeyops';
import Marketing from './Marketing';
import { FormInputs } from './forms/form-constants';
import LandingForm from './forms/LandingForm';
import { axiosCall } from '../../utils/api';
import {
  GOOGLE_ONBOARDING_ROOT,
  ONBOARDING_ROOT,
  VERIFY_ONBOARDING_LINK,
} from '../../utils/api-routes';
import Loading from '../../components/Loading';
import WelcomeImage from './images/Welcome.png';
import ThankYou from './images/ThankYou.png';
import TellUsMore from './images/TellUsMore.png';
import DesignedForYou from './images/DesignedForYou.png';
import { MarketingImage } from './styles/';
import LandingPageCarousel from './components/LandingPageCarousel';
import { useBeforeUnloadPrompt } from '../../custom-hooks/useBeforeUnloadPrompt';
import SubmissionErrorModal from './components/SubmissionErrorModal';
import { validateFields } from './fieldValidationUtils';
import ToastContext from '../../components/Toast/ToastContext';
import { TOAST_TYPES } from '../../utils/constants';
import { DASHBOARD, ENGAGEMENT } from '../../utils/routes';
import { useAuth } from '../../higher-order-components/useAuth';
import { onboardingPostProcessing } from '../../utils/functions/onboarding-post-processing';
import EngagementInfoDisplay from './components/EngagementInfoDisplay';

const CONTACT_FORM_KEYS: string[] = [
  'Landing Form',
  'Account Details Form',
  'Personal Details Form',
  'Practice Details Form',
  'Engage with KeyOps Form',
];

const Onboarding = () => {
  const { i18n, t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { login, loginWithGoogle } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const formDivRef = useRef<HTMLDivElement>();

  const [loading, setLoading] = useState(true);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [currentForm, setCurrentForm] = useState(0);

  const [mobileMarketingDisplayed, setMobileMarketingDisplayed] =
    useState(false);

  // this is to support old referral links,
  // new links will have referral id as a search params
  // we should remove this when we remove ROUTES.REFERRAL route.
  const { referralId: urlReferralId, onboardingId } = useParams();
  const [searchParams] = useSearchParams();
  const referralId = urlReferralId || searchParams.get('referralId');
  const adBoardInviteId = searchParams.get('adBoardInviteId');

  const [attachedEngagement, setAttachedEngagement] = useState(null);
  const [initialContent, setInitialContent] = useState(null);

  useEffect(() => {
    if (!isMobile) {
      if (attachedEngagement) {
        setInitialContent(
          !isMobile && (
            <EngagementInfoDisplay engagement={attachedEngagement} />
          ),
        );
      } else {
        return setInitialContent(<LandingPageCarousel />);
      }
    }
  }, [attachedEngagement]);
  const {
    control,
    register,
    unregister,
    trigger,
    handleSubmit,
    setValue,
    setError,
    getValues,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm<FormInputs>();

  const { triggerToast } = useContext(ToastContext);

  useBeforeUnloadPrompt({ hasUnsavedChanges: isDirty });

  // verify onboarding link
  useEffect(() => {
    const verifyOnboardingLink = async () => {
      try {
        const results = await axiosCall(
          true,
          'get',
          VERIFY_ONBOARDING_LINK,
          false,
          {
            onboardingId,
            referralId,
            adBoardInviteId,
          },
        );
        if (results?.status === 200) {
          setValue('linkId', onboardingId as UUID);
          setValue('referralId', referralId as UUID);
          setValue('adBoardInviteId', adBoardInviteId as UUID);
          setValue('termsOfService', true);
          setValue('privacyPolicy', true);
          setAttachedEngagement(results?.data?.data?.attachedEngagement);
          setLoading(false);
          return;
        }
        throw new Error(results?.message);
      } catch (e) {
        console.log('error verifying onboarding attempt', e);
        navigate({
          pathname: `${location.pathname}/not-found`,
          search: location.search,
        });
      }
    };
    verifyOnboardingLink();
  }, []);

  useEffect(() => {
    if (!loading) {
      analytics.track('Onboarding', { path: location.pathname });
    }
  }, [loading]);

  const nextForm = async () => {
    setCurrentForm(currentForm + 1);
  };

  const backForm = () => {
    setCurrentForm(currentForm - 1);
  };

  const onSubmit = handleSubmit(async (data: FormInputs) => {
    if (formDivRef.current) {
      formDivRef.current.scrollTop = 0;
    }
    const result = await validateFields({
      getValues,
      setError,
      currentForm,
    });
    if (!result) return;
    else if (currentForm < forms.length - 1) {
      analytics.track(`Onboarding Step Completed-${currentForm}`, {
        currentForm,
        question: CONTACT_FORM_KEYS[currentForm],
        email: data['email'],
        onboardingId,
        referralId,
      });
      nextForm();
      return;
    } else {
      try {
        const isGoogleOnboarding = !!data['continue-with-google'];
        data = onboardingPostProcessing(data, i18n);

        const results = await axiosCall(
          true,
          'post',
          isGoogleOnboarding ? GOOGLE_ONBOARDING_ROOT : ONBOARDING_ROOT,
          false,
          {},
          {
            state: data,
            googleJWT: data['continue-with-google'],
          },
        );
        if (results.status >= 200 && results.status < 300) {
          await analytics.track(`Onboarding Completed`, {
            email: data['email'],
            onboardingId,
            referralId,
          });
          onSuccess(results.data?.data?.inviteCreationResult);
          return;
        }
      } catch (error) {
        analytics.track(`Onboarding Failed`, {
          email: data['email'],
          onboardingId,
          referralId,
          error,
        });
        console.error('error saving ' + error.message, error);
        setOpenErrorModal(true);
      }
    }
  });

  const onSuccess = async (inviteCreationResult) => {
    const useAttachedEngagement =
      attachedEngagement && inviteCreationResult?.isAllowed;
    triggerToast({
      type: TOAST_TYPES.SUCCESS,
      //ten minutes, we want it up for a while
      duration: 600000,
      message: useAttachedEngagement
        ? t('onboarding.complete.completeMessageWithEngagement', {
            engagementTitle: attachedEngagement.title,
          })
        : t('onboarding.complete.completeMessage'),
    });
    const isGoogleOnboarding = !!getValues()['continue-with-google'];
    const results = isGoogleOnboarding
      ? await loginWithGoogle(getValues()['continue-with-google'])
      : await login(getValues().email, getValues().password);
    const statusCode = results?.status;
    if (statusCode > 199 && statusCode < 300) {
      navigate(
        useAttachedEngagement
          ? getUrl(ENGAGEMENT, {
              engagementId: attachedEngagement.id,
            })
          : DASHBOARD,
      );
    } else {
      triggerToast({
        type: TOAST_TYPES.ERROR,
        duration: 10000,
        message: t('signIn.errorTitle'),
      });
    }
  };

  const marketingContent = [
    <>{initialContent}</>,
    <>
      {!isMobile && <MarketingImage src={WelcomeImage} />}
      <KeyopsHeader1>{t('onboarding.marketing.welcome')}</KeyopsHeader1>
      <Typography>{t('onboarding.marketing.welcomeSubText')}</Typography>
    </>,
    <>
      {!isMobile && <MarketingImage src={ThankYou} />}
      <KeyopsHeader1>
        {t('onboarding.marketing.thankYou', { name: watch('firstName') })}
      </KeyopsHeader1>
      <Typography>{t('onboarding.marketing.thankYouSubText')}</Typography>
    </>,
    <>
      {!isMobile && <MarketingImage src={TellUsMore} />}
      <KeyopsHeader1>{t('onboarding.marketing.tellUsMore')}</KeyopsHeader1>
      <Typography>{t('onboarding.marketing.tellUsMoreSubText')}</Typography>
    </>,
    <>
      {!isMobile && (
        <img
          src={DesignedForYou}
          alt={t('onboarding.marketing.designedForYouAltText')}
          width={'100%'}
          style={{ marginTop: -70, marginBottom: -50, alignSelf: 'center' }}
        />
      )}
      <KeyopsHeader1>{t('onboarding.marketing.designedForYou')}</KeyopsHeader1>
      <Typography>{t('onboarding.marketing.designedForYouSubText')}</Typography>
    </>,
  ];

  const forms = [
    <LandingForm
      key={CONTACT_FORM_KEYS[0]}
      control={control}
      register={register}
      isValid={isValid}
      setValue={setValue}
      errors={errors}
      onSubmit={onSubmit}
      attachedEngagement={attachedEngagement}
      mobileMarketingDisplayed={mobileMarketingDisplayed}
      setMobileMarketingDisplayed={setMobileMarketingDisplayed}
    />,
    <AccountDetails
      key={CONTACT_FORM_KEYS[1]}
      control={control}
      register={register}
      errors={errors}
    />,
    <PersonalDetails
      key={CONTACT_FORM_KEYS[2]}
      control={control}
      errors={errors}
    />,
    <PracticeDetails
      key={CONTACT_FORM_KEYS[3]}
      control={control}
      register={register}
      unregister={unregister}
      errors={errors}
      setValue={setValue}
      attachedEngagement={attachedEngagement}
    />,
    <EngageWithKeyops
      key={CONTACT_FORM_KEYS[4]}
      control={control}
      register={register}
      trigger={trigger}
      setValue={setValue}
      errors={errors}
    />,
  ];

  return (
    <Box>
      <Box display={'flex'} height={'100dvh'}>
        {!isMobile && (
          <Marketing
            currentForm={currentForm}
            marketingContent={marketingContent}
          />
        )}
        <Box
          component={Card}
          flexGrow={1}
          pt={2}
          pb={2}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
          sx={{
            backgroundColor: isMobile && 'transparent',
            borderTopLeftRadius: !isMobile && 80,
            overflowY: 'auto',
            boxShadow:
              '0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)',
          }}
          ref={formDivRef}
        >
          {loading && <Loading />}
          {currentForm === 0 && !loading && forms[currentForm]}
          {currentForm > 0 && !loading && (
            <Box
              width={!isMobile ? '60%' : '90%'}
              mx={'auto'}
              mt={!isMobile ? 14 : 1}
            >
              {!isMobile ? (
                <Typography
                  variant={'body2'}
                  color={theme.palette.keyops.blue.keyopsBlue}
                  textTransform={'uppercase'}
                >{`Step ${currentForm} of ${forms.length - 1}`}</Typography>
              ) : (
                <Stack width={'100%'} direction={'row'} spacing={1}>
                  {[...Array(forms.length - 1)].map((_, i) => (
                    <Box
                      key={i}
                      height={8}
                      width={'100%'}
                      borderRadius={8}
                      bgcolor={
                        currentForm - 1 < i
                          ? theme.palette.keyops.gray.main
                          : theme.palette.keyops.blue.midnightBlue
                      }
                    />
                  ))}
                </Stack>
              )}
              {isMobile && (
                <Stack
                  spacing={1}
                  mt={4}
                  px={2}
                  py={2}
                  bgcolor={theme.palette.keyops.gray[100]}
                  borderRadius={2}
                >
                  {marketingContent[currentForm]}
                </Stack>
              )}
              <form
                onSubmit={onSubmit}
                style={{ marginTop: !isMobile ? 16 : 32 }}
              >
                {forms[currentForm]}
                <Box
                  display="flex"
                  justifyContent={'space-between'}
                  mt={currentForm === 1 ? 3 : 8}
                >
                  {currentForm > 1 && (
                    <Button
                      variant="outlined"
                      startIcon={<ChevronLeftIcon />}
                      onClick={backForm}
                      sx={{ textTransform: 'capitalize' }}
                    >
                      {t('general.back')}
                    </Button>
                  )}
                  {currentForm === 3 && !getValues().hcpType ? (
                    <></>
                  ) : (
                    <Button
                      type="submit"
                      disabled={!isValid}
                      variant="contained"
                      sx={{
                        textTransform: 'capitalize',
                        width: currentForm > 1 ? 'auto' : '100%',
                        '&:disabled': {
                          backgroundColor:
                            theme.palette.keyops.blue.keyopsBlue50,
                          color: theme.palette.keyops.white.main,
                        },
                      }}
                      disableElevation
                    >
                      {currentForm === forms.length - 1
                        ? t('onboarding.finish')
                        : t('onboarding.next')}
                    </Button>
                  )}
                </Box>
              </form>
            </Box>
          )}
        </Box>
      </Box>
      <SubmissionErrorModal
        openModal={openErrorModal}
        handleClose={() => setOpenErrorModal(false)}
      />
    </Box>
  );
};

export default Onboarding;
