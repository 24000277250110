// External Dependencies
import React, { useEffect, useContext, useState, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Typography, Box } from '@mui/material/';
import { UserDto, EngagementData } from '@keyops-hcp/dtos';
import { useTranslation } from 'react-i18next';

// Internal Dependencies
import EngagementSection from './components/EngagementSection';
import { UserContext } from '../../UserContext';
import { axiosCall } from '../../utils/api';
import ToastContext from '../../components/Toast/ToastContext';
import { DASHBOARD } from '../../utils/routes';
import Loading from '../../components/Loading';
import DashboardHeaderContent from './components/DashboardHeaderContent';
import ClaimRewardPopup from '../Rewards/components/ClaimRewardPopup';
import { RewardOptions } from '../Rewards/data';
import { RewardsTierDto } from '../../dto/rewards.dto';
import NewTierNotificationPopUp from '../../components/NewTierNotificationPopUp';
import { analytics } from '@keyops-hcp/ui-components';

const Dashboard: React.FC = () => {
  // Config
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const referralId = searchParams.get('referralId');

  const { t } = useTranslation();

  // 1. Get User data with corresponding Engagement data
  const context = useContext(UserContext);
  const [userData, setUserData] = React.useState<UserDto>({} as UserDto);
  const [engagementData, setEngagementData] = React.useState({
    surveyInvitations: [],
    adBoardInvitations: [],
  } as EngagementData);
  const [loadingData, setLoadingData] = useState(true);
  const [rewardTierData, setRewardTierData] = useState<RewardsTierDto>({
    tier: '',
  });

  const [openClaimRewardsPopUp, setOpenClaimRewardsPopUp] = useState(false);
  const openClaimRewardsPopUpFn = () => {
    setOpenClaimRewardsPopUp(true);
  };

  const { triggerToast } = useContext(ToastContext);

  // Hooks
  useEffect(() => {
    if (referralId) acceptReferral();
    else navigate(DASHBOARD);
  }, [referralId]);

  useEffect(() => {
    context.getData({
      setUserData,
      setEngagementData,
      setLoadingData,
      setRewardTierData,
    });
  }, []);

  useEffect(() => {
    if (!loadingData) {
      analytics.track('Dashboard');
    }
  }, [loadingData]);

  const acceptReferral = useCallback(async () => {
    const results = await axiosCall(
      false,
      'post',
      'accept-referral',
      false,
      {},
      {
        referralId,
      },
    );
    if (
      results?.response?.status === 403 &&
      results?.response?.data?.data?.specialtyMatch !== undefined &&
      !results?.response?.data?.data?.specialtyMatch
    ) {
      triggerToast({
        type: 'info',
        message: t('dashboard.eligibleSpecialtyWarning', {
          engagementName: results?.response?.data?.data?.engagementName,
          specialtyList:
            results?.response?.data?.data?.allowedSpecialties?.join(', '),
        }),
        duration: 5000,
      });
    } else if (results?.response?.status >= 299) {
      triggerToast({
        type: results?.response?.data?.status,
        message: results?.response?.data?.message,
        duration: 3000,
      });
    } else {
      context.getData({
        setEngagementData,
      });
      triggerToast({
        type: results?.response?.data?.status,
        message: t('dashboard.engagementAdded'),
      });
    }
    navigate(DASHBOARD);
  }, [referralId]);

  // Render
  // 5.
  if (loadingData) {
    // use loading component
    // 5.1 Return load screen if data is not yet loaded
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loading />
        <Typography
          sx={{
            pt: 3,
            fontWeight: 400,
            fontSize: { xs: '18px', md: '24px' },
          }}
        >
          {t('dashboard.loading')}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <DashboardHeaderContent userData={userData} />
      <EngagementSection userData={userData} engagementData={engagementData} />
      {userData.hcpType === 'physician' && (
        <>
          <NewTierNotificationPopUp
            rewardTierData={rewardTierData}
            openClaimRewardsPopUpFn={openClaimRewardsPopUpFn}
          />
          <ClaimRewardPopup
            tierId={rewardTierData?.id ?? ''}
            tier={rewardTierData.tier}
            open={openClaimRewardsPopUp}
            closePopUp={() => setOpenClaimRewardsPopUp(false)}
            rewards={RewardOptions[rewardTierData.tier]}
          />
        </>
      )}
    </>
  );
};

export default Dashboard;
