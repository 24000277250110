import React, { useContext, useEffect } from 'react';
import { Box, Stack } from '@mui/material';

import {
  EngagementDto,
  InvitationDto,
  isHcpUser,
  UserDto,
} from '@keyops-hcp/dtos';
import { useNavigate, useParams } from 'react-router';
import { analytics } from '@keyops-hcp/ui-components';
import { useTranslation } from 'react-i18next';

import { SurveyIframe } from '../Engagement/components/SurveyIframe';
import { UserContext } from '../../UserContext';
import { axiosCall } from '../../utils/api';
import { SURVEY_DONE } from '../../utils/routes';
import Loading from '../../components/Loading';
import { getSpecialty } from '../../utils/functions/common-utils';
import { useSurveyContext } from '../../higher-order-components/AppLayout/SurveyContext';
import ToastContext from '../../components/Toast/ToastContext';
import { TOAST_TYPES } from '../../utils/constants';
import { SurveyHeader } from '../../components/SurveyHeader';

/**
 * This page handles actually displaying the survey.  It is not part of any layout.
 *
 * We allocate as much screen real estate as possible to this since some of the survey
 * questions can be beefy.  This is true for both desktops and mobile.
 *
 * @returns
 */
export const EngagementDisplay = () => {
  const { t } = useTranslation();
  const context = useContext(UserContext);
  const { engagementId } = useParams();
  const navigate = useNavigate();
  const [engagementData, setEngagementData] = React.useState(
    {} as EngagementDto,
  );
  const [invitationData, setInvitationData] = React.useState(
    {} as InvitationDto,
  );
  const [userData, setUserData] = React.useState({} as UserDto);

  const { setSaveAndResumeActive, saveAndResumeActive } = useSurveyContext();
  const { triggerToast } = useContext(ToastContext);

  const [loadingData, setLoadingData] = React.useState(true);

  const handleMessage = async (e) => {
    if (e.data.type === 'surveyCompleted') {
      analytics.track('Engagement Completed', {
        title: engagementData.title,
        id: engagementData.id,
      });
      await axiosCall(
        false,
        'post',
        'engagement',
        engagementId,
        {},
        { state: 'completed' },
      );
      navigate(SURVEY_DONE.replace(':engagementId', engagementId));
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    context.getInvitationData({
      setUserData: setUserData,
      setEngagementData,
      setInvitationData,
      setLoadingData,
      engagementId: engagementId,
    });
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const { state: invitationState } = invitationData;
  useEffect(() => {
    if (!loadingData && engagementData) {
      //track on analytics
      analytics.track(
        invitationState === 'in_progress'
          ? 'Resume Engagement'
          : 'Start Engagement',
        {
          title: engagementData.title,
          id: engagementData.id,
        },
      );
      //set survey to in progress
      if (invitationData.surveyUniqueLink) {
        axiosCall(
          false,
          'post',
          'engagement',
          engagementId,
          {},
          { state: 'in_progress' },
        );
        invitationData.state = 'in_progress';
      }
    }
  }, [loadingData, invitationData]);

  useEffect(() => {
    return () => {
      if (saveAndResumeActive) {
        triggerToast({
          type: TOAST_TYPES.SUCCESS,
          duration: 6000, //ten seconds
          message: t('engagement.saveAndExitSuccessMessage'),
        });
      }
    };
  }, [saveAndResumeActive]);

  if (loadingData) {
    // Return load screen if data is not yet loaded
    return <Loading />;
  }

  if (!isHcpUser(userData)) {
    navigate('/');
    return;
  }

  if (invitationState !== 'new' && invitationState !== 'in_progress') {
    // if the invitation state is not new, show 404
    navigate('/NotFound');
    return;
  }

  const finishLater = () => {
    analytics.track('Save And Exit Engagement', {
      title: engagementData.title,
      id: engagementData.id,
    });

    navigate('/');
  };
  const { id, gender, yearOfBirth, provinceOfPractice } = userData;

  const { practiceLicenseYear, practiceSetting } = userData.hcpProfile;

  const specialty = getSpecialty(userData);
  const link = invitationData.surveyUniqueLink ?? engagementData.link;
  if (invitationData.surveyUniqueLink) {
    setSaveAndResumeActive(true);
  } else {
    setSaveAndResumeActive(false);
  }
  const ssLink = `${link}?Contact_HcpId=${id}&Contact_Gender=${gender}&Contact_PracticeLicenseYear=${practiceLicenseYear}&Contact_YearOfBirth=${yearOfBirth}&Contact_PracticeSetting=${practiceSetting}&Contact_ProvinceOfPractice=${provinceOfPractice}&Contact_Specialty=${specialty}`;

  return (
    <Stack spacing={1}>
      <SurveyHeader
        engagementData={engagementData}
        saveAndResumeActive={saveAndResumeActive}
        finishLater={finishLater}
      />
      <Box>
        <SurveyIframe ssLink={ssLink} />
      </Box>
    </Stack>
  );
};
