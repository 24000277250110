// External Dependencies
import React from 'react';
import {
  Card,
  Table,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  useMediaQuery,
  Box,
} from '@mui/material/';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

// Internal Dependencies
import { TransactionCard } from '../components/TransactionCard';
import { KeyopsHeader2, KeyopsSubTitle1 } from '@keyops-hcp/ui-components';

const PointsHistory = ({ rewardTransactions }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { t } = useTranslation();

  if (!rewardTransactions) {
    return (
      <Box mt={3}>
        <KeyopsHeader2>{t('rewards.pointsHistoryPanel.title')}</KeyopsHeader2>
        <KeyopsSubTitle1 sx={{ mt: 3 }}>
          {t('rewards.pointsHistoryPanel.noTransactions')}
        </KeyopsSubTitle1>
      </Box>
    );
  }

  if (rewardTransactions.length === 0) {
    return <></>;
  }

  if (isMobile) {
    return (
      <Box mt={3}>
        <KeyopsHeader2 data-testid="mobile-points-history">
          {t('rewards.pointsHistoryPanel.title')}
        </KeyopsHeader2>
        <Box mt={2}>
          {rewardTransactions.map((reward, i) => (
            <TransactionCard key={`transaction-card-${i}`} reward={reward} />
          ))}
        </Box>
      </Box>
    );
  }

  return (
    <Box mt={3}>
      <KeyopsHeader2>{t('rewards.pointsHistoryPanel.title')}</KeyopsHeader2>
      <TableContainer
        component={Card}
        variant="outlined"
        sx={{ maxHeight: '100%', borderRadius: 3, mt: 2 }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="rewards transaction table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: 600,
                }}
              >
                {t('rewards.pointsHistoryPanel.activity')}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  pr: '64px',
                  fontWeight: 600,
                }}
              >
                {t('rewards.pointsHistoryPanel.completed')}
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                }}
                align="right"
              >
                {t('rewards.pointsHistoryPanel.pointsEarned')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rewardTransactions.map((reward, i) => (
              <TableRow
                key={`reward-card-${i}`}
                sx={{
                  // '&:last-child td, &:last-child th': { border: 0 },
                  ':hover': {
                    backgroundColor: theme.palette.keyops.gray[100],
                  },
                }}
              >
                <TableCell sx={{ border: 0 }} component="th" scope="row">
                  {reward.type === 'other'
                    ? reward.notes
                    : t(
                        `rewards.pointsHistoryPanel.activityTypes.${reward.type}`,
                      )}
                </TableCell>
                <TableCell sx={{ border: 0 }} align="right">
                  {dayjs(reward.createdAt).format('MMM D, YYYY')}
                </TableCell>
                <TableCell sx={{ border: 0 }} align="right">
                  {reward.amount}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PointsHistory;
