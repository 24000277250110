import React, { useContext, useEffect, useMemo } from 'react';
import { IconType } from 'react-icons';
import { FiLogOut } from 'react-icons/fi';

import { UserDto } from '@keyops-hcp/dtos';

import { DASHBOARD, EARNINGS, REWARDS, LOGOUT } from '../utils/routes';
import { useContactUI } from '../higher-order-components/ContactUIContext';
import { UserContext } from '../UserContext';
import i18n from '../languages/i18n.config';
import {
  Home,
  MessageWrite,
  StarCircle,
  Wallet,
} from '@keyops-hcp/ui-components';

export interface NavItemType {
  name: string;
  icon: IconType;
  path: string;
  action?: (event: React.KeyboardEvent | React.MouseEvent) => void;
}

// top side-nav
const Dashboard: NavItemType = {
  name: i18n.t('navbar.navItems.dashboard'),
  icon: Home,
  path: DASHBOARD,
};
const Earnings: NavItemType = {
  name: i18n.t('navbar.navItems.earnings'),
  icon: Wallet,
  path: EARNINGS,
};
const Rewards: NavItemType = {
  name: i18n.t('navbar.navItems.rewards'),
  icon: StarCircle,
  path: REWARDS,
};

// bottom side-nav
const ContactUs: NavItemType = {
  name: i18n.t('navbar.navItems.contactUs'),
  icon: MessageWrite,
  path: undefined,
};
const Logout: NavItemType = {
  name: i18n.t('navbar.navItems.logout'),
  icon: FiLogOut,
  path: LOGOUT,
};

/**
 * useNavConstants returns the items to display in the landing page left side navbar, depending on the user profile
 * @returns
 */
export const useNavConstants = () => {
  const { openContactUI } = useContactUI();
  const context = useContext(UserContext);
  const [userData, setUserData] = React.useState<UserDto>({} as UserDto);
  useEffect(() => {
    context.getData({
      setUserData,
    });
  }, []);

  const getNavItems = () => {
    const commonNavItems = [Dashboard, Earnings];

    // top nav-items
    const typeSpecificTopNavItems = {
      physician: [...commonNavItems, Rewards],
      pharmacist: [...commonNavItems],
      nurse: [...commonNavItems],
      moderator: [Dashboard],
    };

    // bottom nav-items
    const bottomNavItems = [{ ...ContactUs, action: openContactUI }, Logout];

    return {
      topNavItems: typeSpecificTopNavItems[userData.hcpType] || [],
      bottomNavItems,
    };
  };

  const navItems = useMemo(() => getNavItems(), [userData, openContactUI]);

  return {
    topNavItems: navItems.topNavItems,
    bottomNavItems: navItems.bottomNavItems,
  };
};
