// External Dependencies
import React, { forwardRef, useEffect, useRef, useState } from 'react';

interface GoogleButtonProps {
  callbackFunc: (responseJWT: string) => void;
  shape?: string;
  theme?: string;
  size?: string;
  text?: string;
  showOneTapDialog?: boolean;
}

// Add a global declaration for the 'google' property
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    google: any;
  }
}

// eslint-disable-next-line react/display-name
const GoogleButton = forwardRef<HTMLDivElement, GoogleButtonProps>(
  (
    {
      callbackFunc,
      shape = 'circle',
      theme = 'filled_blue',
      size = 'large',
      text = 'signin_with',
      showOneTapDialog = false,
    },
    ref,
  ) => {
    const resizeRef = useRef(null);
    const [googleLoaded, setGoogleLoaded] = useState(false);

    useEffect(() => {
      const loadGoogle = async () => {
        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
        script.addEventListener('load', () => setGoogleLoaded(true));
      };
      if (!window.google || !googleLoaded) {
        loadGoogle();
      }
      if (window.google && googleLoaded) {
        window.google.accounts.id.initialize({
          client_id: process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID,
          callback: handleCredentialResponse,
        });
        window.google.accounts.id.renderButton(
          document.getElementById('buttonDiv'),
          {
            shape: shape,
            theme: theme,
            size: size,
            text: text,
            width: resizeRef.current.offsetWidth || 400,
          }, // customization attributes
        );
        if (showOneTapDialog) window.google.accounts.id.prompt(); // display the One Tap dialog

        const handleResize = () => {
          window.google.accounts.id.renderButton(
            document.getElementById('buttonDiv'),
            {
              shape: shape,
              theme: theme,
              size: size,
              text: text,
              width: resizeRef?.current?.offsetWidth ?? 400,
            }, // customization attributes
          );
        };

        window.addEventListener('resize', handleResize);
      }
    }, [window.google, googleLoaded]);

    const handleCredentialResponse = async (response) => {
      document.cookie =
        'g_state=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      if (response?.credential) {
        callbackFunc(response?.credential);
      }
    };

    return (
      <>
        <div ref={resizeRef} style={{ maxWidth: '400px', width: '100%' }} />
        <div ref={ref} id="buttonDiv" style={{ marginTop: 0 }}></div>
      </>
    );
  },
);

export default GoogleButton;
