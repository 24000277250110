import React from 'react';
import { CtaButton, KeyOpsIcon } from '@keyops-hcp/ui-components';
import { Box, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { DASHBOARD } from '../../utils/routes';
import { EngagementDto } from '@keyops-hcp/dtos';

interface SurveyHeaderProps {
  engagementData: EngagementDto;
  saveAndResumeActive: boolean;
  finishLater?: () => void;
}

export const SurveyHeader = ({
  engagementData,
  saveAndResumeActive,
  finishLater,
}: SurveyHeaderProps) => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      borderBottom={`1px solid ${palette.keyops.blue.lightSteelBlue20}`}
      height={{ md: '58px', sm: '32px', xs: '32px' }}
      align-items="center"
      bgcolor={palette.keyops.white.main}
      px={{ md: 7, sm: 2, xs: 2 }}
      py={1}
    >
      <Box className="textWithIcons">
        <Box
          width={{ md: '40px', sm: '32px', xs: '32px' }}
          height={{ md: '40px', sm: '32px', xs: '32px' }}
          onClick={() => navigate(DASHBOARD)}
        >
          <KeyOpsIcon />
        </Box>
        <Typography
          component={'span'}
          pl={2}
          fontSize={'16px'}
          fontWeight={500}
          display={{ md: 'block', sm: 'none', xs: 'none' }}
        >
          {engagementData.title}
        </Typography>
      </Box>
      {saveAndResumeActive && (
        <Box mt={{ md: 1, sm: 0, xs: 0 }}>
          <CtaButton
            action={() => {
              finishLater();
            }}
            label={t('engagement.saveAndExit')}
          />
        </Box>
      )}
    </Box>
  );
};
