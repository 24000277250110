import { Specialty } from '@keyops-hcp/constants';
import { SpecialtyOptions } from '../enum-display';

export const isHCPTypeEligible = (hcpType, engagementHCPType): boolean => {
  if (hcpType === engagementHCPType) {
    return true;
  }
  return false;
};

export const isSpecialtyEligible = (
  engagementSpecialties: string[],
  hcpSpecialties: string[],
): boolean => {
  return hcpSpecialties.every((specialty) =>
    engagementSpecialties.includes(specialty) ? true : false,
  );
};

export const formatSpecialties = (specialties: Specialty[]) => {
  let formattedSpecialties = '';
  specialties.forEach((allowedSpecialty, index) => {
    formattedSpecialties += SpecialtyOptions.getDisplay(allowedSpecialty);
    // If 2 specialties, use just "or"
    if (specialties.length > 1 && index === specialties.length - 2) {
      formattedSpecialties += ' or ';
    }
    // If more then 2 specialties, use comas and "or"
    else if (specialties.length > 1 && index !== specialties.length - 1) {
      formattedSpecialties += ', ';
    }
  });
  return formattedSpecialties;
};
