// External dependencies
import React, { useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Grid, Box, useTheme, Typography } from '@mui/material/';
import { useTranslation } from 'react-i18next';
import { FiCheckCircle, FiChevronLeft } from 'react-icons/fi';
import { TextButton } from '@keyops-hcp/ui-components';

// Internal Dependencies
import { EngagementDto } from '@keyops-hcp/dtos';

import { UserContext } from '../../UserContext';
import Loading from '../../components/Loading';
import ShareEngagement from '../../components/ShareEngagement';
import { SurveyHeader } from '../../components/SurveyHeader';
import { DASHBOARD } from '../../utils/routes';

const SurveyDone = () => {
  // 1. Setup State/Data
  const theme = useTheme();
  const context = useContext(UserContext);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { engagementId } = useParams();
  const [engagementData, setEngagementData] = React.useState(
    {} as EngagementDto,
  );
  const [loadingData, setLoadingData] = React.useState(true);

  // 3. Load data
  useEffect(() => {
    context.getInvitationData({
      setEngagementData: setEngagementData,
      setLoadingData: setLoadingData,
      engagementId: engagementId,
    });
    context.setData({}); // We want to fetch data again once survey is completed
  }, []);

  const { referralEnabled } = engagementData;

  // 3. Loader by Default
  if (loadingData) {
    // Return load screen if data is not yet loaded
    return <Loading />;
  }

  const referralSection = (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box
        display="inline-flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          backgroundColor: theme.palette.keyops.gray[100],
          borderRadius: '8px',
          paddingX: { xs: 2, md: 14 },
          paddingY: { xs: 2, md: 3 },
        }}
      >
        <ShareEngagement
          engagementId={parseInt(engagementId)}
          closeReferral={() => undefined}
          postEngagement={true}
        />
      </Box>
    </Box>
  );

  return (
    <>
      <SurveyHeader
        engagementData={engagementData}
        saveAndResumeActive={false}
      />
      <Container
        maxWidth="xl"
        sx={{
          pt: { xs: 0, md: 3 },
          pb: { xs: 2, md: 4 },
        }}
      >
        <Grid
          container
          spacing={{ xs: 0.5, md: 1 }}
          px={{ xs: '5%', md: '25%' }}
          pt={{ xs: '20%', md: '8%' }}
        >
          <Grid
            item
            xs={3}
            md={12}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Box
              sx={{
                width: { xs: 65, md: '96px' },
                height: { xs: 65, md: '96px' },
              }}
            >
              <FiCheckCircle color={theme.palette.keyops.teal} size="100%" />
            </Box>
          </Grid>
          <Grid
            item
            xs={9}
            md={12}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Typography
              fontWeight={500}
              fontSize={{ xs: 18, md: 22 }}
              lineHeight={1.5}
              mt={{ xs: 0, md: 1 }}
            >
              {t('engagement.surveyDoneTitle')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Typography fontWeight={400} fontSize={14} align="center" mt={1}>
              {t('engagement.surveyDoneBody')}
            </Typography>
          </Grid>
        </Grid>

        {referralEnabled && (
          <Grid item xs={12} alignItems="center" sx={{ pt: 5, pb: 5 }}>
            {referralSection}
          </Grid>
        )}

        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          mt={4}
        >
          <TextButton
            aria-label="back-to-home"
            startIcon={<FiChevronLeft />}
            onClick={() => navigate(DASHBOARD)}
            sx={{
              '&:hover': {
                color: theme.palette.keyops.blue.midnightBlue,
                backgroundColor: 'transparent',
              },
              p: 0,
              textTransform: 'capitalize',
            }}
          >
            <Typography variant="body2" color={'inherit'}>
              {t('general.home')}
            </Typography>
          </TextButton>
        </Box>
      </Container>
    </>
  );
};

export default SurveyDone;
