import React from 'react';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { EarningEngagementsResponseDto } from '@keyops-hcp/dtos';

import Loading from '../../../components/Loading';
import useEarningsTableColumns from '../hooks/useEarningsTableColumns';

const EarningsTable = ({
  loadingEarnings,
  earningsData,
}: {
  loadingEarnings: boolean;
  earningsData: EarningEngagementsResponseDto[];
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const columns = useEarningsTableColumns();
  const [sorting, setSorting] = React.useState<SortingState>([
    { id: 'completedAt', desc: true },
  ]);
  const table = useReactTable({
    data: earningsData,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  return (
    <TableContainer
      component={Card}
      variant="outlined"
      sx={{ maxHeight: '100%', borderRadius: 3, overflow: 'auto' }}
    >
      <Table stickyHeader>
        <TableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableCell
                    key={header.id}
                    sx={{
                      textTransform: 'capitalize',
                      backgroundColor: 'white',
                      cursor: header.id === 'amount' ? undefined : 'pointer',
                    }}
                    onClick={
                      header.id === 'amount'
                        ? () => undefined
                        : header.column.getToggleSortingHandler()
                    }
                  >
                    {header.isPlaceholder ? (
                      <></>
                    ) : (
                      flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  ':hover': {
                    backgroundColor: theme.palette.keyops.gray[100],
                  },
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    sx={{
                      border: 0,
                      width:
                        cell.column.id === 'title'
                          ? '50%'
                          : cell.column.id === 'amount'
                            ? '10%'
                            : '20%',
                      whiteSpace: 'pre-wrap',
                      textAlign: cell.column.id === 'amount' ? 'right' : 'left',
                      pr: cell.column.id === 'amount' ? 10 : 0,
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={columns.length}
                align={'center'}
                color={theme.palette.keyops.blue.midnightBlue}
                sx={{ border: 0 }}
              >
                {loadingEarnings ? (
                  <Loading align="top" size="5rem" pt={2} />
                ) : (
                  <Box color={'GrayText'} marginY={2}>
                    {t('earnings.earningsTableNoData')}
                  </Box>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EarningsTable;
