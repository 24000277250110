// External Dependencies
import React, { useState } from 'react';
import { FiX } from 'react-icons/fi';
import { useTheme, Drawer, useMediaQuery } from '@mui/material/';

// Internal Dependencies
import { axiosCall } from '../../../../utils/api';
import BasicModal from '../../../../components/BasicModal';
import CustomReward from './components/CustomReward';
import ClaimRewardOptions from './components/ClaimRewardOptions';
import RewardConfirmation from './components/RewardConfirmation';
import { RewardOptionType } from '../../data/';

interface ClaimRewardPopupProps {
  tierId: string;
  tier: string;
  open: boolean;
  closePopUp: (claimed: boolean) => void;
  rewards: RewardOptionType[];
}

const ClaimRewardPopup = ({
  tierId,
  tier,
  open,
  closePopUp,
  rewards,
}: ClaimRewardPopupProps): JSX.Element => {
  const theme = useTheme();
  const [showCustomReward, setShowCustomReward] = useState(false);
  const [claimed, setClaimed] = useState(false);
  const [modalTopPosition, setModalTopPosition] = useState(undefined);
  const [modalHeight, setModalHeight] = useState(236);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const claimReward = async (
    rewardName: string,
    top: number,
    height: number,
  ) => {
    try {
      await axiosCall(false, 'post', 'user/claim-reward', false, {
        rewardTierId: tierId,
        rewardClaimed: rewardName,
      });
      setModalTopPosition(top);
      setModalHeight(height);
      setClaimed(true);
    } catch (e) {
      console.log(`Error claiming reward ${e}`);
    }
  };

  let popUpContents;
  if (claimed) {
    popUpContents = <RewardConfirmation modalHeight={modalHeight} />;
  } else if (showCustomReward) {
    popUpContents = (
      <CustomReward
        claimReward={claimReward}
        setShowCustomReward={setShowCustomReward}
      />
    );
  } else {
    popUpContents = (
      <ClaimRewardOptions
        tier={tier}
        rewards={rewards}
        claimReward={claimReward}
        setShowCustomReward={setShowCustomReward}
      />
    );
  }

  if (isMobile) {
    return (
      <Drawer
        anchor={'bottom'}
        open={open}
        onClose={closePopUp}
        PaperProps={{
          sx: {
            minHeight: '220px',
            padding: '16px',
            borderRadius: '8px 8px 0 0',
          },
        }}
      >
        <FiX
          onClick={() => closePopUp(claimed)}
          fontSize={24}
          style={{
            position: 'absolute',
            right: 5,
            top: 5,
            padding: '4px 4px 0px 0px',
          }}
        />
        {popUpContents}
      </Drawer>
    );
  }
  return (
    <BasicModal
      open={open}
      handleClose={() => closePopUp(claimed)}
      top={modalTopPosition}
    >
      {popUpContents}
    </BasicModal>
  );
};

export default ClaimRewardPopup;
