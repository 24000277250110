// External Dependencies
import React from 'react';
import { useWatch, get } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// Internal Dependencies
import KeyOpsSingleSelect from '../components/SingleSelect';
import { FORM_FIELDS } from './form-constants';
import KeyOpsTextField from '../components/TextField';
import KeyOpsRadioButtonGroup from '../components/RadioButtonGroup';
import KeyOpsMultiSelect from '../components/MultiSelect';
import {
  FullNurseSpecialtyOptions,
  NurseCareTaskOptions,
  NurseClinicTypeOptions,
  NurseEmploymentStatusOptions,
  NurseHospitalSettingOptions,
  NurseInpatientHospitalDepartmentOptions,
  NurseOccupationCAOptions,
  NurseOccupationUSOptions,
  NurseOutpatientHospitalDepartmentOptions,
  NursePracticeSettingOptions,
  PartialNurseSpecialtyOptions,
} from '../../../utils/enum-display';
import {
  FormControlLabel,
  Checkbox,
  Typography,
  useTheme,
} from '@mui/material';

const formFields = FORM_FIELDS.practiceDetails.nurse;

const NursePracticeDetails = ({
  control,
  register,
  unregister,
  setValue,
  errors,
}) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const [
    countryValue,
    occupationValue,
    specialtyValue,
    practiceSettingValue,
    hospitalSettingValue,
    practiceSettingDetailValue,
    bypassWorkEmailVerificationValue,
  ] = useWatch({
    control,
    name: [
      FORM_FIELDS.accountDetails.country.name,
      formFields.occupation.name,
      formFields.specialty.name,
      formFields.practiceSetting.name,
      formFields.hospitalSetting.name,
      formFields.practiceSettingDetail.name,
      formFields.bypassWorkEmailVerification.name,
    ],
  });

  // Boolean display variables
  const showOtherOccupation = occupationValue === 'other';
  const showUSFields = countryValue === 'US';
  const showPartialSpecialtyOptions = [
    'nurse_practitioner',
    'doctor_of_nursing_practice',
  ].includes(occupationValue);
  const showFullSpecialtyOptions = ['registered_nurse'].includes(
    occupationValue,
  );
  const showOtherSpecialty = specialtyValue && specialtyValue.includes('other');
  const showOtherPracticeSetting = practiceSettingValue === 'other';
  const showHospitalSetting = [
    'academic_hospital',
    'community_hospital',
    'community_teaching_hospital',
  ].includes(practiceSettingValue);
  const showTaskSetting = ['community_health', 'home_care'].includes(
    practiceSettingValue,
  );
  const showClinicSetting = practiceSettingValue === 'clinic_practice';
  const showOtherPracticeSettingDetail =
    practiceSettingDetailValue &&
    ['inpatient-other', 'outpatient-other', 'other'].includes(
      practiceSettingDetailValue,
    );

  // onChange functions
  const onOccupationChange = () => {
    if (!showOtherOccupation) {
      unregister(formFields.otherOccupation.name);
    }
    if (showUSFields) {
      // clear specialty fields on occupation change
      unregister(formFields.specialty.name);
      unregister(formFields.otherSpecialty.name);
    }
  };
  const onSpecialtyChange = () => {
    if (!showOtherOccupation) {
      unregister(formFields.otherSpecialty.name);
    }
  };
  const onPracticeSettingChange = () => {
    if (!showOtherPracticeSetting) {
      unregister(formFields.otherPracticeSetting.name);
    }
    if (!showHospitalSetting) {
      unregister(formFields.hospitalSetting.name);
      unregister(formFields.practiceSettingDetail.name);
    }
  };
  const onHospitalSettingChange = () => {
    unregister(formFields.practiceSettingDetail.name);
  };
  const onPracticeSettingDetailChange = () => {
    if (!showOtherPracticeSettingDetail) {
      unregister(formFields.otherPracticeSettingDetail.name);
    }
  };
  const handleBypassCheckboxChange = (event) => {
    setValue(formFields.bypassWorkEmailVerification.name, event.target.checked);
  };

  return (
    <>
      {/* Occupation fields */}
      <KeyOpsSingleSelect
        fieldName={formFields.occupation.name}
        placeholder={formFields.occupation.label}
        rules={{
          required: t('onboarding.forms.fieldErrors.required', {
            field: formFields.occupation.label,
          }),
        }}
        options={
          showUSFields ? NurseOccupationUSOptions : NurseOccupationCAOptions
        } // options depend on CA & US
        control={control}
        errors={errors}
        onChange={onOccupationChange}
      />
      {showOtherOccupation && (
        <KeyOpsTextField
          fieldName={formFields.otherOccupation.name}
          placeholder={formFields.otherOccupation.label}
          rules={{
            required: t('onboarding.forms.fieldErrors.required', {
              field: formFields.otherOccupation.label,
            }),
          }}
          register={register}
          errors={errors}
        />
      )}
      {/* US only fields */}
      {showUSFields && (
        <>
          {/* Specialty fields*/}
          {showPartialSpecialtyOptions && (
            <KeyOpsMultiSelect
              fieldName={formFields.specialty.name}
              placeholder={formFields.specialty.label}
              rules={{
                required: t('onboarding.forms.fieldErrors.required', {
                  field: formFields.specialty.label,
                }),
              }}
              options={PartialNurseSpecialtyOptions}
              control={control}
              errors={errors}
              limit={4}
              onChange={onSpecialtyChange}
            />
          )}
          {showFullSpecialtyOptions && (
            <KeyOpsMultiSelect
              fieldName={formFields.specialty.name}
              placeholder={formFields.specialty.label}
              rules={{
                required: t('onboarding.forms.fieldErrors.required', {
                  field: formFields.specialty.label,
                }),
              }}
              options={FullNurseSpecialtyOptions}
              control={control}
              errors={errors}
              limit={4}
              onChange={onSpecialtyChange}
            />
          )}
          {showOtherSpecialty && (
            <KeyOpsTextField
              fieldName={formFields.otherSpecialty.name}
              placeholder={formFields.otherSpecialty.label}
              rules={{
                required: t('onboarding.forms.fieldErrors.required', {
                  field: formFields.otherSpecialty.label,
                }),
              }}
              register={register}
              errors={errors}
            />
          )}
        </>
      )}
      {/* Practice setting */}
      <KeyOpsSingleSelect
        fieldName={formFields.practiceSetting.name}
        placeholder={formFields.practiceSetting.label}
        rules={{
          required: t('onboarding.forms.fieldErrors.required', {
            field: formFields.practiceSetting.label,
          }),
        }}
        options={NursePracticeSettingOptions}
        control={control}
        errors={errors}
        onChange={onPracticeSettingChange}
      />
      {showOtherPracticeSetting && (
        <KeyOpsTextField
          fieldName={formFields.otherPracticeSetting.name}
          placeholder={formFields.otherPracticeSetting.label}
          rules={{
            required: t('onboarding.forms.fieldErrors.required', {
              field: formFields.otherPracticeSetting.label,
            }),
          }}
          register={register}
          errors={errors}
        />
      )}
      {/* Practice setting details */}
      {/* 1. Hospital details */}
      {showHospitalSetting && (
        <>
          <KeyOpsSingleSelect
            fieldName={formFields.hospitalSetting.name}
            placeholder={formFields.hospitalSetting.label}
            rules={{
              required: t('onboarding.forms.fieldErrors.required', {
                field: formFields.hospitalSetting.label,
              }),
            }}
            options={NurseHospitalSettingOptions}
            control={control}
            errors={errors}
            onChange={onHospitalSettingChange}
          />
          {/* Hospital department - CA only */}
          {!showUSFields && hospitalSettingValue && (
            <KeyOpsSingleSelect
              fieldName={formFields.practiceSettingDetail.name}
              placeholder={'Hospital department'}
              rules={{
                required: t('onboarding.forms.fieldErrors.required', {
                  field: 'Hospital department',
                }),
              }}
              options={
                hospitalSettingValue === 'inpatient'
                  ? NurseInpatientHospitalDepartmentOptions
                  : NurseOutpatientHospitalDepartmentOptions
              }
              control={control}
              errors={errors}
              onChange={onPracticeSettingDetailChange}
            />
          )}
          {showOtherPracticeSettingDetail && (
            <KeyOpsTextField
              fieldName={formFields.otherPracticeSettingDetail.name}
              placeholder={formFields.otherPracticeSettingDetail.label}
              rules={{
                required: t('onboarding.forms.fieldErrors.required', {
                  field: formFields.otherPracticeSettingDetail.label,
                }),
              }}
              register={register}
              errors={errors}
            />
          )}
        </>
      )}
      {/* 2. Task details - CA only */}
      {!showUSFields && showTaskSetting && (
        <>
          <KeyOpsSingleSelect
            fieldName={formFields.practiceSettingDetail.name}
            placeholder={'Tasks'}
            rules={{
              required: t('onboarding.forms.fieldErrors.required', {
                field: 'Tasks',
              }),
            }}
            options={NurseCareTaskOptions}
            control={control}
            errors={errors}
            onChange={onPracticeSettingDetailChange}
          />
          {showOtherPracticeSettingDetail && (
            <KeyOpsTextField
              fieldName={formFields.otherPracticeSettingDetail.name}
              placeholder={formFields.otherPracticeSettingDetail.label}
              rules={{
                required: t('onboarding.forms.fieldErrors.required', {
                  field: formFields.otherPracticeSettingDetail.label,
                }),
              }}
              register={register}
              errors={errors}
            />
          )}
        </>
      )}
      {/* 3. Clinic details - CA only */}
      {!showUSFields && showClinicSetting && (
        <>
          <KeyOpsSingleSelect
            fieldName={formFields.practiceSettingDetail.name}
            placeholder={'Clinic setting'}
            rules={{
              required: t('onboarding.forms.fieldErrors.required', {
                field: 'Clinic setting',
              }),
            }}
            options={NurseClinicTypeOptions}
            control={control}
            errors={errors}
            onChange={onPracticeSettingDetailChange}
          />
          {showOtherPracticeSettingDetail && (
            <KeyOpsTextField
              fieldName={formFields.otherPracticeSettingDetail.name}
              placeholder={formFields.otherPracticeSettingDetail.label}
              rules={{
                required: t('onboarding.forms.fieldErrors.required', {
                  field: formFields.otherPracticeSettingDetail.label,
                }),
              }}
              register={register}
              errors={errors}
            />
          )}
        </>
      )}

      {/* Employment status */}
      <KeyOpsRadioButtonGroup
        fieldName={formFields.employmentStatus.name}
        placeholder={formFields.employmentStatus.label}
        rules={{
          required: t('onboarding.forms.fieldErrors.required', {
            field: formFields.employmentStatus.label,
          }),
        }}
        options={NurseEmploymentStatusOptions}
        control={control}
        errors={errors}
      />
      {/* License number */}
      <KeyOpsTextField
        fieldName={formFields.licenseNumber.name}
        placeholder={formFields.licenseNumber.label}
        rules={{
          required: t('onboarding.forms.fieldErrors.required', {
            field: formFields.licenseNumber.label,
          }),
        }}
        register={register}
        errors={errors}
      />
      {/* License issuance year */}
      <KeyOpsTextField
        fieldName={formFields.practiceLicenseYear.name}
        placeholder={formFields.practiceLicenseYear.label}
        rules={{
          required: t('onboarding.forms.fieldErrors.required', {
            field: formFields.practiceLicenseYear.label,
          }),
        }}
        register={register}
        errors={errors}
      />
      {/* Work email */}
      <KeyOpsTextField
        fieldName={formFields.workEmail.name}
        placeholder={formFields.workEmail.label}
        inputProps={{ 'data-testid': formFields.workEmail.name }}
        rules={{
          required: t('onboarding.forms.fieldErrors.required', {
            field: formFields.workEmail.label,
          }),
        }}
        register={register}
        helperText={t('onboarding.forms.practiceDetails.workHelperText')}
        errors={bypassWorkEmailVerificationValue ? {} : errors}
        disabled={bypassWorkEmailVerificationValue}
      />
      {/* bypass work email verification */}
      {(get(errors, formFields.workEmail.name)?.message ===
        t(`onboarding.forms.fieldErrors.workEmailNotValid`) ||
        bypassWorkEmailVerificationValue) && (
        <FormControlLabel
          style={{ marginTop: '16px', alignItems: 'flex-start' }}
          control={
            <Checkbox
              disableRipple={true}
              sx={{
                '& .MuiSvgIcon-root': { fontSize: 20 },
                pl: 0,
                pr: 0.5,
                color: palette.keyops.black,
                marginTop: -1.1,
              }}
              onChange={handleBypassCheckboxChange}
              name={formFields.bypassWorkEmailVerification.name}
              checked={bypassWorkEmailVerificationValue}
            />
          }
          label={
            <Typography variant="body2">
              {formFields.bypassWorkEmailVerification.label}
            </Typography>
          }
        />
      )}
      {/* Other details*/}
      <KeyOpsTextField
        fieldName={formFields.otherDetails.name}
        placeholder={formFields.otherDetails.label}
        register={register}
        errors={errors}
      />
    </>
  );
};

export default NursePracticeDetails;
