import React, { useState } from 'react';
import {
  StyledInputRoot,
  StyledInputElement,
  StyledInputAdornment,
  IconButton,
} from './styles.js';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const PasswordInput = (props) => {
  const { id = 'password', onChange, placeholder } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <StyledInputRoot>
      <StyledInputElement
        placeholder={placeholder ?? 'Password'}
        id={id}
        type={showPassword ? 'text' : 'password'}
        disableUnderline={true}
        onChange={(e) => onChange(e)}
        endAdornment={
          <StyledInputAdornment position={'end'}>
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </StyledInputAdornment>
        }
        {...props}
      />
    </StyledInputRoot>
  );
};

export default PasswordInput;
