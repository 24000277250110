import React from 'react';
import {
  Navigate,
  Outlet,
  createSearchParams,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { useAuth } from '../useAuth';
import Loading from '../../components/Loading';
import { LOGIN } from '../../utils/routes';

const PrivateRoutes = () => {
  const { authed, loading } = useAuth();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  if (loading) {
    return <Loading />;
  }

  return authed ? (
    <Outlet />
  ) : (
    <Navigate
      preserveQueryString={true}
      to={{
        pathname: LOGIN,
        search: `?${createSearchParams(searchParams)}`,
      }}
      state={{ from: location }}
      replace
    />
  );
};

export default PrivateRoutes;
