// External Dependencies
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { styled } from '@mui/system';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { DASHBOARD } from '../../utils/routes';

export const BackButtonStyled = styled(Button)`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <BackButtonStyled aria-label="back" onClick={() => navigate(DASHBOARD)}>
      <ArrowBackIosNewIcon sx={{ color: '#3376D1' }} />
    </BackButtonStyled>
  );
};

export default BackButton;
