import React, { useEffect, useState, Fragment } from 'react';

import { RewardOptions, RewardTierNames } from '../data';
import { axiosCall } from '../../../utils/api';
import ClaimButton from '../components/ClaimButton';
import ClaimRewardPopup from '../components/ClaimRewardPopup';

const ClaimRewardSection = () => {
  const [unclaimedRewards, setUnclaimedRewards] = useState([]);
  const [openPopups, setOpenPopups] = React.useState({});

  const fetchUnclaimedRewardsData = async () => {
    try {
      const unclaimedRewardsResults = await axiosCall(
        false,
        'get',
        'user/rewards-tiers',
        false,
        {
          claimed: false,
          tiers: [
            RewardTierNames.silver,
            RewardTierNames.gold,
            RewardTierNames.platinum,
          ],
        },
      );
      setUnclaimedRewards(unclaimedRewardsResults.data.data?.rewardTiers);
    } catch (e) {
      console.log(`Error retrieving unclaimed rewards ${e}`);
    }
  };

  const toggleClaimRewardPopup = (tierId) => (claimed: boolean) => {
    if (claimed) {
      fetchUnclaimedRewardsData();
    }
    setOpenPopups((prevOpenPopups) => ({
      ...prevOpenPopups,
      [tierId]: !prevOpenPopups[tierId],
    }));
  };

  useEffect(() => {
    fetchUnclaimedRewardsData();
  }, []);

  const claimButtons = unclaimedRewards?.map((rewardTier) => (
    <Fragment key={rewardTier.id}>
      <ClaimButton
        tier={rewardTier.tier}
        toggleClaimRewardPopup={toggleClaimRewardPopup(rewardTier.id)}
      />
      <ClaimRewardPopup
        tierId={rewardTier.id}
        tier={rewardTier.tier}
        open={openPopups[rewardTier.id] ? openPopups[rewardTier.id] : false}
        closePopUp={toggleClaimRewardPopup(rewardTier.id)}
        rewards={RewardOptions[rewardTier.tier]}
      />
    </Fragment>
  ));

  return <>{claimButtons}</>;
};

export default ClaimRewardSection;
