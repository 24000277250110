// External Dependencies
import React, { useMemo } from 'react';
import { Card, Box, Typography, Grid } from '@mui/material/';
import { useTheme } from '@mui/material/styles';
import { FiCheckCircle } from 'react-icons/fi';
import { FaRegPaperPlane } from 'react-icons/fa';
import { SlBubbles } from 'react-icons/sl';
import { useTranslation } from 'react-i18next';

// Internal Dependencies
import { KeyopsHeader2, KeyopsSubTitle1 } from '@keyops-hcp/ui-components';

const WayCard = ({ wayIcon, wayText, wayPoints }) => {
  const { palette } = useTheme();

  return (
    <Grid item xs={12} sm={6} md={6} lg={4}>
      <Card
        elevation={0}
        sx={{
          border: `1px solid ${palette.keyops.gray[200]}`,
          py: 1.5,
          textAlign: 'center',
        }}
      >
        {wayIcon}
        <Typography variant={'body1'}>{wayText}</Typography>
        <Typography
          variant={'h6'}
          fontSize={'1.125rem'}
          color={palette.keyops.blue.midnightBlue}
          mt={1}
        >
          {wayPoints}
        </Typography>
      </Card>
    </Grid>
  );
};

const WaysToEarn = () => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  const WAYS_TO_EARN = [
    {
      wayKey: 'earn-testimonial',
      wayIcon: (
        <SlBubbles fontSize={45} color={palette.keyops.blue.midnightBlue} />
      ),
      wayText: t('rewards.waysToEarnPanel.earnTestimonial'),
      wayPoints: t('rewards.waysToEarnPanel.earnTestimonialPoints'),
    },
    {
      wayKey: 'earn-referral',
      wayIcon: (
        <FaRegPaperPlane
          fontSize={45}
          color={palette.keyops.blue.midnightBlue}
        />
      ),
      wayText: t('rewards.waysToEarnPanel.earnReferral'),
      wayPoints: t('rewards.waysToEarnPanel.earnReferralPoints'),
    },
    {
      wayKey: 'earn-engagement',
      wayIcon: (
        <FiCheckCircle fontSize={45} color={palette.keyops.blue.midnightBlue} />
      ),
      wayText: t('rewards.waysToEarnPanel.earnEngagement'),
      wayPoints: t('rewards.waysToEarnPanel.earnEngagementPoints'),
    },
  ];

  const waysToEarnCards = useMemo(
    () =>
      WAYS_TO_EARN.map((way) => (
        <WayCard
          key={way.wayKey}
          wayIcon={way.wayIcon}
          wayText={way.wayText}
          wayPoints={way.wayPoints}
        />
      )),
    [WAYS_TO_EARN],
  );

  return (
    <Box mt={3}>
      <KeyopsHeader2>
        {t('rewards.waysToEarnPanel.wayToEarnTitle')}
      </KeyopsHeader2>
      <KeyopsSubTitle1 mt={0.5}>
        {t('rewards.waysToEarnPanel.wayToEarnDescription')}
      </KeyopsSubTitle1>
      <Box mt={3}>
        <Grid columnSpacing={2} rowSpacing={2} container>
          {waysToEarnCards}
        </Grid>
      </Box>
    </Box>
  );
};

export default WaysToEarn;
